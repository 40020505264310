<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs
        v-model="activeName"
        :tabArr="tabs"
        :paramsData="params"
        :handlerParams="handlerParams"
      />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from '@/base/components/Default/RouterTabs.vue'
import { getCircleTopic } from '@/modules/activity/api/activity-post/list.js'
import { getActivity } from '../../api/activity-list/detail'

export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: [
        'ActivityDetail',
        'ImageText',
        'InteractionList',
        'SignUpList',
        'ActivityPostList',
        'SetComments',
        'ListConfig',
        'Information',
        'Ranking',
        'DataStatistics',
        'ActivityTag',
      ], // 带tab栏的页面路由name
      params: {}, // 路由传输数据
      activeName: 'ImageText', //当前选中tab
      // tab配置
      tabs: [
        {
          label: '活动详情',
          name: 'ActivityDetail',
          params: true,
          hidden: false,
        },
        { label: '图文列表', name: 'ImageText', params: true, hidden: false },
        // { label: "互动列表", name: "InteractionList", params: true },
        { label: '报名名单', name: 'SignUpList', params: true, hidden: false },
        { label: '排位设置', name: 'Ranking', params: true, hidden: false },
        {
          label: '信息关联',
          name: 'Information',
          params: true,
          hidden: () => !this.isCycOrganization,
        },
        {
          label: '发帖列表',
          name: 'ActivityPostList',
          params: true,
          hidden: () => !this.isCircle,
        },
        {
          label: '评论管理',
          name: 'SetComments',
          params: true,
          hidden: () => !this.isCircle,
        },
        // {label: "数据统计", name: "DataStatistics", params: true, hidden: false },
        { label: '签到码', name: 'ListConfig', params: true, hidden: false },
        {
          label: '活动标签',
          name: 'ActivityTag',
          params: true,
          hidden: () => !this.isCycOrganization,
        },
        // { label: "心得列表", name: "ExperienceList", params: true },
        // { label: "心得评论", name: "ExperienceComments", params: true },
      ],
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    isCircle() {
      return this.$store.getters.tenant.features.includes('circle')
    },
  },
  created() {
    this.ShowRouterTabs = this.listArr.includes(this.routeName) ? true : false
    this.activeName = this.$route.name

    if (
      this.$route.params.id &&
      this.$route.params.id !== '0' &&
      ![
        'ActivityPostDetail',
        'ActivityEditPost',
        'ActivityFormDesign',
        'AddActivityNew',
        'ActivityDetailNew',
      ].includes(this.activeName)
    ) {
      // 判断是否是线上活动，线上活动隐藏两个tab 报名表单和排位设置
      this.hideTabById(this.$route.params.id)
    }
  },
  watch: {
    routeName: {
      handler(val) {
        this.ShowRouterTabs = this.listArr.includes(val) ? true : false
        if (this.listArr.includes(val)) {
          // if (val == "PostList") {
          //   // this.$route.params.activity_id = this.$route.params.id;
          //   params.activity_id = this.$route.params.id;
          //   this.$delete(params, "id");
          // }
          this.params = this.$route.params
          this.activeName = val
        }
      },
      immediate: true,
    },
    // 监听路由参数变化
    '$route.params': {
      handler(val, old) {
        this.params = val
      },
    },
  },
  components: { RouterTabs },
  methods: {
    // 判断是线上活动还是线下活动，隐藏对应路由
    hideTabById(id) {
      getActivity({ id }).then((res) => {
        if (!res.data.type) {
          this.hiddenTab('SignUpList')
          this.hiddenTab('Ranking')
          this.hiddenTab('ListConfig')
        } else {
          this.showTab('SignUpList')
          this.showTab('Ranking')
          this.showTab('ListConfig')
        }
      })
    },
    // 特殊处理将要跳转的参数
    async handlerParams(params, toRouteName) {
      // 帖子列表，评论列表需要请求 帖子 id
      if (['ActivityPostList', 'SetComments'].includes(toRouteName)) {

        let res = await getCircleTopic({ activity_id: params.id })
        params.topic_id = res.data.id
      }
      return params
    },
    // 隐藏对应路由的tab
    hiddenTab(routeName) {
      let index = this.tabs.findIndex((el) => el.name === routeName)

      if (index !== -1) {
        this.tabs[index].hidden = true
      }
    },
    // 显示对应路由的tab
    showTab(routeName) {
      let index = this.tabs.findIndex((el) => el.name === routeName)
      if (index !== -1) {
        this.tabs[index].hidden = false
      }
    },
    // 设置路由是否显示
    setRouterTabs(flag) {
      this.ShowRouterTabs = flag
    },
  },
  beforeRouteLeave(to, from, next) {
    // 路由离开显示回对应三级菜单
    this.showTab('SignUpList')
    this.showTab('Ranking')
    this.showTab('ListConfig')
    next()
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}
</style>
