

import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 获取活动话题id
export const getCircleTopic = data => {
  return api({
    url: '/admin/cyc_activity/activity/circleTopic',
    method: 'post',
    data
  });
};
